@use '../../styles/mixins' as mixins;

.cta-grid-block {

	@include mixins.sectionStyles;

	.cta-grid-wrapper {
		@include mixins.grid(200px);
	}
	
}